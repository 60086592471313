<template>
  <v-container :fluid="containerFluid">
    <template v-if="!hideAlerts">
      <verify-alert/>
      <v-alert
        v-if="adStatus === 'pending_fix'"
        class="mt-3 mb-5"
        border="left"
        colored-border
        type="error"
        style="border: thin solid rgba(0, 0, 0, 0.12); margin-bottom: -10px;"
      >
        <b>{{$t('pending_fix_alert_title')}}</b>
        <br/>
        {{$t('pending_fix_alert_description')}}
        <br/>
        <br/>
        <div v-if="ad && ad.approve_errors && ad.approve_errors.errors && ad.approve_errors.errors[$i18n.locale]"
        v-html="ad.approve_errors.errors[$i18n.locale]"></div>
        <br/>
        {{$t('pending_fix_alert_description_after')}}
      </v-alert>
    </template>

    <v-row class="mt-1 py-0 mb-3 px-4">
      <v-col class="d-flex align-center justify-space-between py-0 pl-0">
        <v-toolbar-title v-if="title">
          <h1 class="text-truncate font-weight-bold text-h4">{{title}}</h1>
        </v-toolbar-title>
        <div class="d-flex align-center">
          <div v-if="
            ['pending', 'pending_fix', 'listed', 'unlisted', 'active'].includes(adStatus)
            && $route.params.categoryConst === 'apartment'
          ">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :to="{name: 'Calendar', params: {
                    categoryConst: $route.params.categoryConst,
                    id: String(ad.id)
                  }}"
                  x-small
                  fab
                  color="primary"
                  class="mr-3"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-calendar</v-icon>
                </v-btn>
              </template>
              <span>{{$t('Calendar')}}</span>
            </v-tooltip>
          </div>
          <div v-if="previewUrl">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <a
                  :href="previewUrl"
                  target="_blank"
                >
                  <v-btn
                    x-small
                    fab
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>
                </a>
              </template>
              <span>{{$t('View')}}</span>
            </v-tooltip>
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="auto" class="d-flex align-center pl-md-0 pb-md-0 pb-0 pt-2 pt-md-0 px-0">
        <div v-if="adStatus === 'pending_fix'">
          <v-tooltip  bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click.stop.prevent="$emit('requestApproval')"
                color="error"
                class="mr-3"
                dark
                rounded
                v-bind="attrs"
                v-on="on"
              >
                {{$t('pending_fix_request_approve_btn')}}
              </v-btn>
            </template>
            <span>{{$t('pending_fix_request_approve_btn_tooltip')}}</span>
          </v-tooltip>
        </div>
        <div v-if="adStatus">
          <v-chip :color="adStatus === 'active' ? 'success' : 'default'">
            <span v-if="ad.active[$i18n.locale] == '0' && adStatus == 'listed'">
              {{ $t('AdFormStatus.unlisted') }}
            </span>
            <span v-else>
              {{ $t((ad.active[$i18n.locale] == '1' && adStatus == 'listed') ? 'AdFormStatus.listed' : `AdFormStatus.${adStatus}`) }}
            </span>
          </v-chip>
        </div>
      </v-col>
    </v-row>
    <slot name="header"/>
    <v-tabs
      v-if="tabs && tabs.length"
      @change="tabChaned($event)"
      class="mb-5">
      <template v-for="(tab, i) in tabs">
        <v-tab
          v-if="tab && viewAsAdmin"
          :key="i"
          :to="{name: tab.route, query: { viewAsAdmin: viewAsAdmin } }">
            {{$t(tab.name)}}
        </v-tab>
        <v-tab
          v-else-if="tab"
          :key="i"
          :to="{name: tab.route }">
            {{$t(tab.name)}}
        </v-tab>
      </template>
    </v-tabs>

    <v-row
      no-gutters>
      <v-col
        :xl="hasInfo ? 6 : 12"
        :md="hasInfo ? 8 : 12"
        cols="12"
      >
          <errors-backend/>
          <slot name="body"></slot>
          <component
            v-if="currentTab"
            v-bind:is="currentTab.component"></component>
      </v-col>
      <v-col
        v-if="hasInfo"
        :xl="hasInfo ? 6 : 12"
        :md="hasInfo ? 4 : 12"
        cols="12"
      >
          <info
            v-for="(info, index) in infosComputed" :key="index"
            class="ml-md-10 mt-4 mt-md-0 mb-10"
            :title="info.title"
            :description="info.description"
            :icon="info.icon"
            :youtube="info.youtube"
          />
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.toolbar__items {
  flex-wrap: wrap;
}
</style>
<script>
import ViewAsAdminMixin from '@/lib/ViewAsAdminMixin';
import MetaTitleMixin from '@/lib/MetaTitleMixin';
import Info from '@/components/Info.vue';
import VerifyAlert from '@/components/VerifyAlert.vue';
import { mapGetters } from 'vuex';

export default {
  mixins: [MetaTitleMixin, ViewAsAdminMixin],
  components: {
    Info,
    VerifyAlert,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    tabs: {
      type: Array,
      required: false,
    },
    infos: {
      type: Array,
      required: false,
    },
    previewUrl: {
      type: String,
      required: false,
    },
    adStatus: {
      type: String,
      required: false,
    },
    ad: {
      type: Object,
      required: false,
    },
    containerFluid: {
      type: Boolean,
      default: false,
    },
    hideAlerts: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentTab: undefined,
      showApartmentDeactivateModal: false,
    };
  },
  mounted() {
    // Catch all UserUpdate events and update user state
    this.$echo.private(`users.${this.user.id}`)
      .listen('UserUpdated', (e) => {
        this.$store.dispatch('updateUser', e.user);
      });
    this.matchTabComponent();

    if (this.isAdmin) {
      document.body.classList.add('hide-hubspot-chat');
    }
  },
  computed: {
    ...mapGetters(['user', 'isAdmin']),
    hasInfo() {
      if (this.infos && this.infos.length) {
        return true;
      }
      return this.currentTab && this.currentTab.infos;
      // && this.currentTab.info.title
      // && this.currentTab.info.description;
    },
    infosComputed() {
      if (this.currentTab && this.currentTab.infos && this.currentTab.infos.length) {
        return this.currentTab.infos;
      }
      return this.infos;
    },
    // youtubeInfo() {
    //   if (this.info && this.info.youtube) {
    //     return this.info.youtube;
    //   }
    //   if (this.currentTab && this.currentTab.info && this.currentTab.info.youtube) {
    //     return this.currentTab.info.youtube;
    //   }
    //   return undefined;
    // },
  },
  methods: {
    // ToDo -> make computed???
    matchTabComponent() {
      if (this.tabs) {
        const tab = this.tabs.find(
          (o) => o.route === this.$router.currentRoute.name,
        );
        if (tab) {
          this.currentTab = tab;
          // document.title = this.$t(tab.title);
          this.setMetaTitle();
        }
      }
    },
    tabChaned() {
      this.$store.dispatch('clearErrors');
      this.matchTabComponent();
    },
    setMetaTitle() {
      if (this.currentTab && this.currentTab.title) {
        this.$title = this.$t(this.currentTab.title);
      }
    },
  },
};
</script>

import Vue from 'vue';
import VueRouter from 'vue-router';
import storage from '@/lib/storage';
import store from '../store';
import Dashboard from '../views/Dashboard.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/ads-list/:categoryConst',
    name: 'AdsSubcategoriesList',
    component: () => import(/* webpackChunkName: "about" */ '../views/ads/AdsSubcategoriesList.vue'),
    props: (route) => ({ categoryConst: route.params.categoryConst }),
    meta: {
      auth: true,
    },
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      auth: true,
    },
  },
  {
    path: '/activity-log',
    name: 'activity-log',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ActivityLog.vue'),
  },
  {
    path: '/reservations',
    name: 'reservations',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Reservations.vue'),
  },
  {
    path: '/inquiries',
    name: 'inquiries',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Inquiries.vue'),
  },
  {
    path: '/inquiry/:id',
    name: 'inquiry',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Inquiries.vue'),
    props: (route) => ({ id: route.params.id }),
  },
  {
    path: '/my-travel/inquiries',
    name: 'my-travel.inquiries',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/MyTravel.vue'),
  },
  {
    path: '/my-travel/reservations',
    name: 'my-travel.reservations',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/MyTravel.vue'),
  },
  {
    path: '/user-settings/account',
    name: 'user-settings.account',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/UserSettings.vue'),
  },
  {
    path: '/user-settings/change-password',
    name: 'user-settings.change-password',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/UserSettings.vue'),
  },

  {
    path: '/user-settings/payouts',
    name: 'user-settings.payouts',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/UserSettings.vue'),
  },

  {
    path: '/listings/create',
    name: 'AdsCreate',
    component: () => import(/* webpackChunkName: "listings-create" */ '../views/ads/AdsCreate.vue'),
    props: (route) => ({ id: route.params.id }), // ToDo obrisi ovo
  },
  {
    path: '/listings/:categoryConst',
    name: 'Ads',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "listings" */ '../views/ads/Ads.vue'),
    props: (route) => ({ categoryConst: route.params.categoryConst }),
  },
  {
    path: '/listing/:categoryConst/:id/calendar',
    name: 'Calendar',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "listing-form" */ '../views/Calendar.vue'),
    props: (route) => ({
      id: route.params.id,
      focusEventId: route.params.focusEventId,
    }),
  },
  {
    path: '/listing/:categoryConst/:id/reservation/:reservationId',
    name: 'EditReservation',
    meta: {
      auth: true,
      // roles: ['Admin'],
    },
    component: () => import(/* webpackChunkName: "listing-form" */ '../views/EditReservation.vue'),
    props: (route) => ({ id: route.params.id, reservationId: route.params.reservationId }),
  },
  {
    path: '/listings/:categoryConst/create',
    name: 'AdFormCreate',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "listings-create-category" */ '../views/ads/AdForm.vue'),
    props: (route) => ({ categoryConst: route.params.categoryConst }),
  },
  {
    path: '/listing/:categoryConst/:id/:stepSlug?',
    name: 'AdForm',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "listing-form" */ '../views/ads/AdForm.vue'),
    props: (route) => ({ id: route.params.id, categoryConst: route.params.categoryConst, stepSlug: route.params.stepSlug }),
  },
  {
    path: '/listing/verify-listing',
    name: 'VerifyPage',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "listing-form" */ '../views/VerifyPage.vue'),
    props: (route) => ({ id: route.params.id }),
  },
  {
    path: '/government-id',
    name: 'GovernmentId',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "listing-form" */ '../views/GovernmentId.vue'),
  },
  {
    path: '/delete-account',
    name: 'DeleteAccount',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "listing-form" */ '../views/DeleteAccount.vue'),
  },
  {
    path: '/auth/:provider/callback',
    component: {
      template: '<div class="auth-component"></div>',
    },
  },
  {
    path: '/logout-redirect',
    name: 'LogoutRedirect',
    component: () => import(/* webpackChunkName: "logout-redirect" */ '../views/auth/LogoutRedirect.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/auth/Register.vue'),
  },
  {
    path: '/apple-login-add-name/:id',
    name: 'apple-login-add-name',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "register" */ '../views/auth/AppleAddName.vue'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/auth/ResetPassword.vue'),
  },
  {
    path: '/email/verify/:id/:hash',
    name: 'VerifyEmail',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/auth/VerifyEmail.vue'),
  },
  {
    path: '*',
    name: 'page-do-not-exists',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/404.vue'),
  },
  {
    path: '/page-do-not-exists',
    name: '404',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/404.vue'),
  },
  {
    path: '/tv',
    name: 'TV',
    meta: {
      auth: true,
      roles: ['Admin'],
    },
    component: () => import(/* webpackChunkName: "listing-form" */ '../views/TV.vue'),
  },
  {
    path: '/tv/double',
    name: 'TVTwo',
    meta: {
      auth: true,
      roles: ['Admin'],
    },
    component: () => import(/* webpackChunkName: "listing-form" */ '../views/TVTwo.vue'),
  },
  {
    path: '/tv/advance-payment',
    name: 'TV.advance-payment',
    meta: {
      auth: true,
      roles: ['Admin'],
    },
    component: () => import(/* webpackChunkName: "listing-form" */ '../views/TVSingle.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // const loggedIn = localStorage.getItem('user');
  const loggedIn = storage.getItem('access_token');

  // this.app.$store.getters.isAdmin;
  // const { isAdmin } = store.getters;
  // console.log('isAdmin', isAdmin);
  // console.log('user', store.getters.user);

  // if user is logged in - redirect him to dashboard
  if (loggedIn && to.matched.some((record) => ['Login', 'Register', 'ResetPassword'].includes(record.name))) {
    next('/');
    return;
  }

  // if social auth is used inside cordova app
  const isCordovaApp = window.navigator && window.navigator.userAgent && window.navigator.userAgent.includes('cordova-app');
  const isCordovaAppIOS = window.navigator && window.navigator.userAgent && window.navigator.userAgent.includes('cordova-app-ios');
  const isGoogleAuthCallback = to.fullPath.includes('/auth/google/callback');
  const isAppleAuthCallback = to.fullPath.includes('/auth/apple/callback');

  if ((isCordovaApp && isGoogleAuthCallback) || (isCordovaAppIOS && isAppleAuthCallback)) {
    const routeParams = new URLSearchParams(window.location.search);
    const authData = {
      clientId: isGoogleAuthCallback
        ? process.env.VUE_APP_GOOGLE_CLIENT_ID
        : process.env.VUE_APP_APPLE_CLIENT_ID,
      code: routeParams.get('code'),
      data: routeParams.get('data'),
      provider: isGoogleAuthCallback ? 'google' : 'apple',
    };
    store.dispatch('loginForApp', authData);
  }

  // if route has auth & user is not logged in - redirect him to login
  if (to.matched.some((record) => record.meta.auth) && !loggedIn) {
    next('/login');
    return;
  }
  next();
});

export default router;

<template>
  <div class="iframe-wrapper">
    <iframe
      width="100%"
      height="100%"
      class="rounded-lg iframe"
      :src="url"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
      allowfullscreen
    >
    </iframe>
  </div>
</template>
<script>
export default {
  props: {
    url: {
      required: true,
      type: String,
    },
  },
};
</script>
<style scoped>
  .iframe-wrapper{
    padding-top: 50%;
    position: relative;
    height: 100%;
    width: 100%;
  }
  .iframe{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
</style>

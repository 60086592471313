export default {
  watch: {
    '$i18n.locale': function updateTitle() {
      if (typeof this.setMetaTitle !== 'undefined') {
        this.setMetaTitle();
      } else {
        console.log('setMetaTitle function do not exists');
      }
    },
  },
};

<template>
  <v-alert
    v-if="hasValidationErrors"
    border="left"
    colored-border
    type="error"
    class="box-sha"
  >
    <div v-for="(error,field) in errorsBackend" :key="field">
      <!-- <b>{{field}}</b>: {{error[0]}} -->
      {{error[0]}}
    </div>
  </v-alert>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['errorsBackend', 'hasValidationErrors']),
  },
};
</script>

<template>
  <v-app>
      <v-main>
        <v-container fluid fill-height>
            <v-layout align-center justify-center flex-column>
              <v-card :loading="loading"
                outlined width="448" class="rounded-lg">
                <template slot="progress">
                  <v-progress-linear
                    height="5"
                    indeterminate
                  ></v-progress-linear>
                </template>
                  <v-card-text>
                    <v-img
                        class="mx-auto mt-5 mb-6"
                        lazy-src="@/assets/logo.svg"
                        max-width="150"
                        src="@/assets/logo.svg"
                    ></v-img>
                    <!-- <v-img
                        class="mx-auto mt-5"
                        lazy-src="https://bookaweb.com/img/logo.svg"
                        max-width="150"
                        src="https://bookaweb.com/img/logo.svg"
                    ></v-img> -->
                    <h1 class="text-center font-weight-regular black--text text-lg-h5 mt-3 mb-8"
                      >{{title}}</h1>

                    <errors-backend/>

                    <slot name="body"></slot>

                  </v-card-text>
                  <v-card-actions class="pb-8 pt-0 px-4">
                    <slot name="footer"></slot>
                  </v-card-actions>
              </v-card>
              <div class="auth-layout-footer">
                <v-menu
                  top
                  close-on-click
                >
                  <template v-slot:activator="{ on }">
                    <a
                  class="text-caption
                  font-weight-regular text-decoration-none black--text darken-5 "
                      v-on="on">
                      {{$i18n.locale == 'sr' ? 'Srpski' : 'Engleski'}}
                      <v-icon>mdi-menu-down</v-icon>
                    </a>
                  </template>

                  <v-list class="pa-0">
                    <v-list-item dense link @click="setLocale('sr')">
                      <v-list-item-title>SR</v-list-item-title>
                    </v-list-item>
                    <v-list-item dense link @click="setLocale('en')">
                      <v-list-item-title>EN</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-spacer></v-spacer>
                <div class="text-body-2">

                  <a
                    class="font-weight-bold text-decoration-none grey--text darken-5 mr-5"
                    v-for="link in links"
                    :key="link.name"
                    :href="link.url"
                    target="_blank"
                  >{{link.name}}</a>
                </div>
              </div>
            </v-layout>
        </v-container>
      </v-main>
  </v-app>
</template>
<script>
import axios from 'axios';

export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    loading: {
      default: false,
    },
  },
  data() {
    return {
      linksAll: {
        en: [
          {
            url: 'https://bookaweb.com/privacy-policy',
            name: 'Privacy',
          },
          {
            url: 'https://bookaweb.com/terms-and-conditions',
            name: 'Terms',
          },
        ],
        sr: [
          {
            url: 'https://bookaweb.com/sr/politika-privatnosti',
            name: 'Privatnost',
          },
          {
            url: 'https://bookaweb.com/sr/uslovi-koriscenja',
            name: 'Uslovi',
          },
        ],
      },
    };
  },
  computed: {
    links() {
      return this.linksAll[this.$i18n.locale];
    },
  },
  mounted() {
    if (this.$route.query.lang
        && (this.$route.query.lang === 'sr' || this.$route.query.lang === 'en')) {
      this.$i18n.locale = this.$route.query.lang;
      axios.defaults.headers.common['App-Language'] = this.$i18n.locale;
    }

    // hide hubspot chat bubble
    document.body.classList.add('hide-hubspot-chat');
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      axios.defaults.headers.common['App-Language'] = this.$i18n.locale;
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":_vm.containerFluid}},[(!_vm.hideAlerts)?[_c('verify-alert'),(_vm.adStatus === 'pending_fix')?_c('v-alert',{staticClass:"mt-3 mb-5",staticStyle:{"border":"thin solid rgba(0, 0, 0, 0.12)","margin-bottom":"-10px"},attrs:{"border":"left","colored-border":"","type":"error"}},[_c('b',[_vm._v(_vm._s(_vm.$t('pending_fix_alert_title')))]),_c('br'),_vm._v(" "+_vm._s(_vm.$t('pending_fix_alert_description'))+" "),_c('br'),_c('br'),(_vm.ad && _vm.ad.approve_errors && _vm.ad.approve_errors.errors && _vm.ad.approve_errors.errors[_vm.$i18n.locale])?_c('div',{domProps:{"innerHTML":_vm._s(_vm.ad.approve_errors.errors[_vm.$i18n.locale])}}):_vm._e(),_c('br'),_vm._v(" "+_vm._s(_vm.$t('pending_fix_alert_description_after'))+" ")]):_vm._e()]:_vm._e(),_c('v-row',{staticClass:"mt-1 py-0 mb-3 px-4"},[_c('v-col',{staticClass:"d-flex align-center justify-space-between py-0 pl-0"},[(_vm.title)?_c('v-toolbar-title',[_c('h1',{staticClass:"text-truncate font-weight-bold text-h4"},[_vm._v(_vm._s(_vm.title))])]):_vm._e(),_c('div',{staticClass:"d-flex align-center"},[(
          ['pending', 'pending_fix', 'listed', 'unlisted', 'active'].includes(_vm.adStatus)
          && _vm.$route.params.categoryConst === 'apartment'
        )?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"to":{name: 'Calendar', params: {
                  categoryConst: _vm.$route.params.categoryConst,
                  id: String(_vm.ad.id)
                }},"x-small":"","fab":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar")])],1)]}}],null,false,4072102041)},[_c('span',[_vm._v(_vm._s(_vm.$t('Calendar')))])])],1):_vm._e(),(_vm.previewUrl)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('a',{attrs:{"href":_vm.previewUrl,"target":"_blank"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","fab":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)],1)]}}],null,false,4255762723)},[_c('span',[_vm._v(_vm._s(_vm.$t('View')))])])],1):_vm._e()])],1),_c('v-col',{staticClass:"d-flex align-center pl-md-0 pb-md-0 pb-0 pt-2 pt-md-0 px-0",attrs:{"cols":"12","md":"auto"}},[(_vm.adStatus === 'pending_fix')?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"error","dark":"","rounded":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('requestApproval')}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('pending_fix_request_approve_btn'))+" ")])]}}],null,false,1465111087)},[_c('span',[_vm._v(_vm._s(_vm.$t('pending_fix_request_approve_btn_tooltip')))])])],1):_vm._e(),(_vm.adStatus)?_c('div',[_c('v-chip',{attrs:{"color":_vm.adStatus === 'active' ? 'success' : 'default'}},[(_vm.ad.active[_vm.$i18n.locale] == '0' && _vm.adStatus == 'listed')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('AdFormStatus.unlisted'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t((_vm.ad.active[_vm.$i18n.locale] == '1' && _vm.adStatus == 'listed') ? 'AdFormStatus.listed' : ("AdFormStatus." + _vm.adStatus)))+" ")])])],1):_vm._e()])],1),_vm._t("header"),(_vm.tabs && _vm.tabs.length)?_c('v-tabs',{staticClass:"mb-5",on:{"change":function($event){return _vm.tabChaned($event)}}},[_vm._l((_vm.tabs),function(tab,i){return [(tab && _vm.viewAsAdmin)?_c('v-tab',{key:i,attrs:{"to":{name: tab.route, query: { viewAsAdmin: _vm.viewAsAdmin } }}},[_vm._v(" "+_vm._s(_vm.$t(tab.name))+" ")]):(tab)?_c('v-tab',{key:i,attrs:{"to":{name: tab.route }}},[_vm._v(" "+_vm._s(_vm.$t(tab.name))+" ")]):_vm._e()]})],2):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"xl":_vm.hasInfo ? 6 : 12,"md":_vm.hasInfo ? 8 : 12,"cols":"12"}},[_c('errors-backend'),_vm._t("body"),(_vm.currentTab)?_c(_vm.currentTab.component,{tag:"component"}):_vm._e()],2),(_vm.hasInfo)?_c('v-col',{attrs:{"xl":_vm.hasInfo ? 6 : 12,"md":_vm.hasInfo ? 4 : 12,"cols":"12"}},_vm._l((_vm.infosComputed),function(info,index){return _c('info',{key:index,staticClass:"ml-md-10 mt-4 mt-md-0 mb-10",attrs:{"title":info.title,"description":info.description,"icon":info.icon,"youtube":info.youtube}})}),1):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
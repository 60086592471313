export const objectStorage = {
  data: {},
  setItem(key, value) {
    objectStorage.data[key] = value;
  },
  getItem(key, defaultValue = null) {
    return objectStorage.data[key] || defaultValue;
  },
  removeItem(key) {
    delete objectStorage.data[key];
  },
};

function createStorage() {
  try {
    if (typeof localStorage === 'undefined') {
      return objectStorage;
    }

    window.localStorage.getItem('test');

    return window.localStorage;
  } catch (e) {
    return objectStorage;
  }
}

const storage = createStorage();

export default {
  setItem(key, value) {
    storage.setItem(key, JSON.stringify(value));
  },
  getItem(key, defaultValue = null) {
    const item = storage.getItem(key);
    if (item === null) {
      return defaultValue;
    }

    try {
      return JSON.parse(item);
    } catch (e) {
      console.warn(`Could not parse "${key}" got: ${item}`);

      return defaultValue;
    }
  },
  removeItem(key) {
    storage.removeItem(key);
  },
};

<template>
  <main-layout :title="$t('Dashboard')">
    <template v-slot:body>
    <!-- <verify-alert/> -->
    <v-row>
      <v-col
        md="6"
        sm="6"
      >
        <v-card
          outlined
          class="px-6 pb-6 rounded-lg"
          height="100%"
        >
        <div class="d-flex flex-column mb-4">
            <h2 class="text-center my-4">{{$t('For guests')}}</h2>
            <v-img
                class="rounded mx-auto"
                width="100%"
                max-height="250px"
                src="@/assets/dashboard-guests.png"
              ></v-img>
        </div>
          <div class="d-flex flex-column flex-lg-row">
            <v-row>
              <v-col>
                <h3>
                  {{$t('How to book accomodation')}}
                </h3>
                <p>
                  {{ $t('dashboard_how_to_book_accomodation') }}
                </p>
              </v-col>
            </v-row>
          </div>
          <div class="mb-2">
            <h3>
              {{$t('Terms of accomodation reservation')}}
            </h3>
            <p>
              {{$t('Terms of reservation text')}}
            </p>
            <a target="_blank" :href="
              $i18n.locale === 'sr'
              ? 'https://bookaweb.com/sr/uslovi-rezervacije-smestaja'
              : 'https://bookaweb.com/accommodation-reservation-conditions'"
            >{{$t('Terms of reservation')}}</a>
          </div>
          <div class="mt-6">
            <h4>
              {{$t('We suggest some of the destinations')}}
            </h4>
          </div>
          <a
            v-for="(link,i) in links[$i18n.locale]"
            :key="`link_${i}`"
            class="mr-3"
            :href="link.url"
          >{{link.text}}</a>
        </v-card>
      </v-col>

      <v-col
        md="6"
        sm="6"
    >
      <v-card
        outlined
        class="px-6 pb-6 rounded-lg"
      >
      <div class="d-flex flex-column mb-4">
        <h2 class="text-center my-4">{{$t('For owners')}}</h2>
        <v-img
            class="rounded mx-auto"
            width="100%"
            max-height="250px"
            src="@/assets/dashboard-owners.jpeg"
          ></v-img>
      </div>
        <div class="d-flex flex-column flex-lg-row">
          <v-row>
            <v-col>
              <h3>
                {{$t('How to list accomodation')}}
              </h3>
              <p>
                {{$t('dashboard_how_to_add_accomodation')}}
              </p>
            </v-col>
          </v-row>
        </div>
        <div class="mt-4 mb-2">
          <div class="d-flex justify-left">
            <youtube-iframe url="https://www.youtube.com/embed/hZ3tfzxmB6w"></youtube-iframe>
          </div>
        </div>
        <div class="mt-5 mb-2">
          <div v-if="$i18n.locale === 'sr'" class="mb-5">
            <h3>
              Help Centar za Vlasnike
            </h3>
            <p>
              Detaljne informacije kako da iz ugla vlasnika koristite platformu i dodjete do većeg broja rezervacija.
              <br/>
              <a target="_blank" href="https://bookaweb.com/sr/help-centar-za-vlasnike">Help Centar za Vlasnike</a>
            </p>
          </div>
          <h3>
            {{$t('Terms of renting accomodations')}}
          </h3>
          <p>
            {{$t('Terms of renting text')}}
            <br/>
            <a target="_blank" :href="
              $i18n.locale === 'sr'
              ? 'https://bookaweb.com/sr/uslovi-izdavanja-smestaja'
              : 'https://bookaweb.com/conditions-for-renting-an-accommodation'"
            >{{$t('Terms of renting')}}</a>
          </p>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
          width="100%"
          class="pa-5 d-lg-flex flex-column mt-3 rounded-lg"
          outlined
        >
              <h3 class="mb-4">{{$t("Let's grow together")}}</h3>
              <p>
                {{ $t('grow_together_text') }}
              </p>
              <div class="d-flex align-items-center">
                <a
                  target="_blank"
                  :href="
                  $i18n.locale === 'sr'
                  ? 'https://docs.google.com/forms/d/e/1FAIpQLSeSY04w0QYFN0U-bhEgRw7epvwmCdrNty7wLfdG7R9VxVRAMg/viewform?usp=sf_link'
                  : 'https://docs.google.com/forms/d/e/1FAIpQLSduqOEFbhuUEWPyyYmt1b5d88DosTeqDxWHMn5oWs37jOrIpg/viewform?usp=sf_link'"
                >{{$t('Form for hosts')}}</a>
                <a
                  target="_blank"
                  :href="
                  $i18n.locale === 'sr'
                  ? 'https://docs.google.com/forms/d/e/1FAIpQLSd71f8Rvx-8KGDBGeALLFUJ_eGvEKhmxLBUVT_VuZOpzZyNyQ/viewform?usp=sf_link'
                  : 'https://docs.google.com/forms/d/e/1FAIpQLSdskGozU9h2mq-frXnF2Lzs9FGFNAuiHmIH_gamLxuc_-RlLA/viewform?usp=sf_link'"
                  class="ml-4"
                >{{$t('Form for guests')}}</a>
              </div>
        </v-card>
      </v-col>
    </v-row>
    </template>
  </main-layout>
</template>
<script>
</script>
<script>
import VerifyAlert from '@/components/VerifyAlert.vue';
import YoutubeIframe from '@/components/YoutubeIframe.vue';

export default {
  components: {
    VerifyAlert,
    YoutubeIframe,
  },
  data() {
    return {
      links: {
        sr: [
          {
            text: "Beograd",
            url: `${process.env.VUE_APP_APP_URL}/sr/beograd/apartmani`,
          },
          {
            text: "Zlatibor",
            url: `${process.env.VUE_APP_APP_URL}/sr/zlatibor/apartmani`,
          },
          {
            text: "Novi Sad",
            url: `${process.env.VUE_APP_APP_URL}/sr/novi-sad/apartmani`,
          },
          {
            text: "Niš",
            url: `${process.env.VUE_APP_APP_URL}/sr/nis/apartmani`,
          },
        ],
        en: [
          {
            text: "Belgrade",
            url: `${process.env.VUE_APP_APP_URL}/belgrade/apartments`,
          },
          {
            text: "Zlatibor",
            url: `${process.env.VUE_APP_APP_URL}/zlatibor/apartments`,
          },
          {
            text: "Novi Sad",
            url: `${process.env.VUE_APP_APP_URL}/novi-sad/apartments`,
          },
          {
            text: "Niš",
            url: `${process.env.VUE_APP_APP_URL}/nis/apartments`,
          },
        ]
      }
    };
  },
}
</script>

const constPlugin = {};

/* eslint-disable no-param-reassign */
constPlugin.install = (Vue) => {
  // Vue.prototype.$BUSINESS = 'business';
  // Vue.prototype.$INDIVIDUAL = 'individual';
  Vue.prototype.$CONFIG = {
    USER: {
      ACCOUNT_TYPE: {
        INDIVIDUAL: 'individual',
        BUSINESS: 'business',
      },
    },
    PANEL: {
      DEFAULT_COLOR: 'black',
    },
  };
};
/* eslint-enable no-param-reassign */

export default constPlugin;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-layout',{attrs:{"title":_vm.$t('Dashboard')},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"md":"6","sm":"6"}},[_c('v-card',{staticClass:"px-6 pb-6 rounded-lg",attrs:{"outlined":"","height":"100%"}},[_c('div',{staticClass:"d-flex flex-column mb-4"},[_c('h2',{staticClass:"text-center my-4"},[_vm._v(_vm._s(_vm.$t('For guests')))]),_c('v-img',{staticClass:"rounded mx-auto",attrs:{"width":"100%","max-height":"250px","src":require("@/assets/dashboard-guests.png")}})],1),_c('div',{staticClass:"d-flex flex-column flex-lg-row"},[_c('v-row',[_c('v-col',[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('How to book accomodation'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('dashboard_how_to_book_accomodation'))+" ")])])],1)],1),_c('div',{staticClass:"mb-2"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('Terms of accomodation reservation'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('Terms of reservation text'))+" ")]),_c('a',{attrs:{"target":"_blank","href":_vm.$i18n.locale === 'sr'
            ? 'https://bookaweb.com/sr/uslovi-rezervacije-smestaja'
            : 'https://bookaweb.com/accommodation-reservation-conditions'}},[_vm._v(_vm._s(_vm.$t('Terms of reservation')))])]),_c('div',{staticClass:"mt-6"},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('We suggest some of the destinations'))+" ")])]),_vm._l((_vm.links[_vm.$i18n.locale]),function(link,i){return _c('a',{key:("link_" + i),staticClass:"mr-3",attrs:{"href":link.url}},[_vm._v(_vm._s(link.text))])})],2)],1),_c('v-col',{attrs:{"md":"6","sm":"6"}},[_c('v-card',{staticClass:"px-6 pb-6 rounded-lg",attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex flex-column mb-4"},[_c('h2',{staticClass:"text-center my-4"},[_vm._v(_vm._s(_vm.$t('For owners')))]),_c('v-img',{staticClass:"rounded mx-auto",attrs:{"width":"100%","max-height":"250px","src":require("@/assets/dashboard-owners.jpeg")}})],1),_c('div',{staticClass:"d-flex flex-column flex-lg-row"},[_c('v-row',[_c('v-col',[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('How to list accomodation'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('dashboard_how_to_add_accomodation'))+" ")])])],1)],1),_c('div',{staticClass:"mt-4 mb-2"},[_c('div',{staticClass:"d-flex justify-left"},[_c('youtube-iframe',{attrs:{"url":"https://www.youtube.com/embed/hZ3tfzxmB6w"}})],1)]),_c('div',{staticClass:"mt-5 mb-2"},[(_vm.$i18n.locale === 'sr')?_c('div',{staticClass:"mb-5"},[_c('h3',[_vm._v(" Help Centar za Vlasnike ")]),_c('p',[_vm._v(" Detaljne informacije kako da iz ugla vlasnika koristite platformu i dodjete do većeg broja rezervacija. "),_c('br'),_c('a',{attrs:{"target":"_blank","href":"https://bookaweb.com/sr/help-centar-za-vlasnike"}},[_vm._v("Help Centar za Vlasnike")])])]):_vm._e(),_c('h3',[_vm._v(" "+_vm._s(_vm.$t('Terms of renting accomodations'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('Terms of renting text'))+" "),_c('br'),_c('a',{attrs:{"target":"_blank","href":_vm.$i18n.locale === 'sr'
            ? 'https://bookaweb.com/sr/uslovi-izdavanja-smestaja'
            : 'https://bookaweb.com/conditions-for-renting-an-accommodation'}},[_vm._v(_vm._s(_vm.$t('Terms of renting')))])])])])],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"pa-5 d-lg-flex flex-column mt-3 rounded-lg",attrs:{"width":"100%","outlined":""}},[_c('h3',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$t("Let's grow together")))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('grow_together_text'))+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('a',{attrs:{"target":"_blank","href":_vm.$i18n.locale === 'sr'
                ? 'https://docs.google.com/forms/d/e/1FAIpQLSeSY04w0QYFN0U-bhEgRw7epvwmCdrNty7wLfdG7R9VxVRAMg/viewform?usp=sf_link'
                : 'https://docs.google.com/forms/d/e/1FAIpQLSduqOEFbhuUEWPyyYmt1b5d88DosTeqDxWHMn5oWs37jOrIpg/viewform?usp=sf_link'}},[_vm._v(_vm._s(_vm.$t('Form for hosts')))]),_c('a',{staticClass:"ml-4",attrs:{"target":"_blank","href":_vm.$i18n.locale === 'sr'
                ? 'https://docs.google.com/forms/d/e/1FAIpQLSd71f8Rvx-8KGDBGeALLFUJ_eGvEKhmxLBUVT_VuZOpzZyNyQ/viewform?usp=sf_link'
                : 'https://docs.google.com/forms/d/e/1FAIpQLSdskGozU9h2mq-frXnF2Lzs9FGFNAuiHmIH_gamLxuc_-RlLA/viewform?usp=sf_link'}},[_vm._v(_vm._s(_vm.$t('Form for guests')))])])])],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }
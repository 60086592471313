export default {
  data() {
    return {
      viewAsAdmin: false,
    };
  },
  beforeMount() {
    this.viewAsAdmin = this.$route.query.viewAsAdmin
      ? this.$route.query.viewAsAdmin
      : false;
  },
};

<template>
  <v-alert v-if="show" outlined color="red darken-2" class="rounded-xl mt-5 mb-5">
    <!-- <v-card class="rounded-lg d-flex mb-6 text--white white--text" outlined> -->
    <div class="d-flex align-center">
      <div class="px-6">
        <v-icon size="42" color="black">mdi-alert-circle-outline</v-icon>
      </div>
      <div>
        <div class="d-flex flex-column mb-3">
          <span class="font-weight-bold text-body-1 p-0">
            {{ $t("Confirm important details") }}
          </span>
          <span class="text-body-2 red--text">
            {{ $t("Required to publish") }}
          </span>
        </div>
        <router-link :to="{ name: 'VerifyPage' }" class="text-body-2">{{
          $t("Start")
        }}</router-link>
      </div>
    </div>
    <!-- </v-card> -->
  </v-alert>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['user']),
    show() {
      return this.user.apartmentCount > 0 && (this.user.email_verified_at === null || this.user.hasGovernmentId === false || this.user.payoutsCount === 0 || this.user.hasUserDetails === false);
    },
  },
};
</script>

<template>
    <v-card
        :max-width="$vuetify.breakpoint.mdAndUp ? 344 : ''"
        outlined
      >
        <v-card-text>
            <img
                v-if="icon"
                :src="iconSrc"
                height="35px"
            />
            <p class="title text--secundary mb-0">
                {{ $t(title) }}
            </p>
            <div v-html="$t(description)"></div>
        </v-card-text>
        <div
          v-if="youtube"
          class="mx-2 my-2"
        >
          <youtube-iframe
            v-if="youtube"
            :url="youtube"
          />
        </div>
      </v-card>
</template>
<script>
import YoutubeIframe from '@/components/YoutubeIframe.vue';

export default {
  components: {
    YoutubeIframe,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    description: {
      required: true,
      type: String,
    },
    icon: {
      required: false,
      type: String,
    },
    youtube: {
      required: false,
      type: String,
    },
  },
  computed: {
    iconSrc() {
      /* eslint-disable global-require */
      return require(`@/assets/icons/${this.icon}.svg`); // eslint-disable-line import/no-dynamic-require
      /* eslint-enable global-require */
    },
  },
};
</script>

<template>
    <v-dialog
      v-model="show"
      :fullscreen="fullscreen"
      persistent
      :max-width="maxWidth"
      :content-class="cssClass">
        <v-card :loading="loading" class="card-outter">
            <template slot="progress">
              <v-progress-linear
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-overlay :value="loading">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
            <div
              :class="{'sticky-header': stickyHeader}"
              class="modal-header"
            >
              <div class="d-flex justify-space-between d-sm-block">
                <v-card-title v-if="title" :class="titleClass">
                    <span class="text-h6 text-md-h5" style="word-break: break-word;">{{title}}</span>
                </v-card-title>
                <v-btn
                  v-if="!hideTopCloseButton"
                  icon
                  small
                  fab
                  color="black"
                  class="close-button"
                  @click="$emit('close')"
                >
                  <v-icon>
                      mdi-window-close
                  </v-icon>
                </v-btn>
              </div>
              <v-card-subtitle v-if="subtitle">
                  {{subtitle}}
              </v-card-subtitle>
              <v-card-text v-if="description" v-html="description"></v-card-text>
              <slot name="header"></slot>
            </div>

            <slot></slot>
            <v-card-actions  class="card-actions" v-if="hideActions">
              <v-btn
                v-if="showActionsClear"
                @click.prevent="$emit('clear')"
                text
                class="text-subtitle-1 font-weight-medium"
              >
              {{$t(clearText)}}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                v-if="!hideActionsCancel"
                @click.prevent="$emit('close')"
                text
                color="blue darken-1"
                class="text-subtitle-1 font-weight-medium mr-3"
              >
              {{$t(closeText)}}
              </v-btn>
              <v-btn
                @click="$emit('save')"
                :color="primaryButtonClass || 'primary'"
                class="save-button"
                :class="primaryButtonClass"
              >
                {{$t(saveText)}}
              </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<style lang="scss">
.card-outter {
  position: relative;
}
.rule-manager{
  .card-outter {
    height: 100%;
    & .container {
      height: 90%;
    }
  }
}
.rule-manager-hide-save .save-button{
  display: none;
}
</style>
<style lang="scss" scoped>
.disable-save .save-button{
  opacity: 0.5;
  pointer-events: none;
}
.close-button{
  margin-block: 8px;
  margin-inline: 8px;
}
@media (min-width: 600px) {
  .close-button {
    position: absolute;
    right: 4px;
    top: 4px;
  }
}
.sticky-header{
  position: sticky;
  top: 0;
  background: white;
  z-index: 99;
  border-bottom: 1px solid #ccc;
}
.card-actions {
  position: sticky !important;
  width: 100%;
  border-top: 1px solid #ccc;
  background: white;
  bottom: 0;
  z-index: 99;
}
.calendar-datepicker .card-actions {
  position: absolute !important;
}
</style>
<script>
export default {
  props: {
    show: {
      default: false,
    },
    hideTopCloseButton: Boolean,
    hideActionsCancel: Boolean,
    showActionsClear: {
      default: false,
    },
    stickyHeader: {
      default: false,
    },
    title: {
      default: undefined,
    },
    titleClass: {
      default: '',
    },
    subtitle: {
      default: undefined,
    },
    description: {
      default: undefined,
    },
    fullscreen: {
      default: false,
    },
    hideActions: {
      default: true,
    },
    loading: {
      default: false,
    },
    maxWidth: {
      default: 800,
    },
    saveText: {
      default: 'Save',
    },
    closeText: {
      default: 'Cancel',
    },
    clearText: {
      default: 'Clear',
    },
    cssClass: {
      default: '',
    },
    primaryButtonClass: {
      default: '',
    },
  },
};
</script>

import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSocialauth from 'vue-social-auth';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import Echo from 'laravel-echo';
import storage from '@/lib/storage';
import constPlugin from '@/constPlugin';

import { MediaLibraryAttachment } from '@spatie/media-library-pro-vue2-attachment';
import { MediaLibraryCollection } from '@spatie/media-library-pro-vue2-collection';

import * as VueGoogleMaps from 'vue2-google-maps';
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import VuePageTitle from 'vue-page-title';
import i18n from './i18n';

import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import App from './App.vue';

window.Pusher = require('pusher-js');

let accessToken = null;
try {
  accessToken = storage.getItem('access_token');
} catch (e) {
  console.warn(e);
}

Vue.prototype.$echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_MIX_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_MIX_PUSHER_APP_CLUSTER,
  forceTLS: true,
  authEndpoint: process.env.VUE_APP_MIX_PUSHER_AUTHORIZATION_ENDPOINT,
  auth: { headers: { Authorization: `Bearer ${accessToken}` } },
});

Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyBtLb9KvR8jDXUszMq18q1OPlCsSf-Mjpc',
    key: 'AIzaSyBnCMDjrDlmKzWQgttqrwlh2m3cRV_CsYE',
    // This is required to use the Autocomplete plugin
    libraries: 'places', // 'places,drawing,visualization'
  },
});

Vue.use(VuetifyGoogleAutocomplete, {
  vueGoogleMapsCompatibility: true,
// Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
// apiKey: 'AIzaSyAnLaidyFuQjNqQ5HsA5mdSqJXV6qOnn2M',
// // version: '...', // Optional
// // language: 'rs', // Optional
// installComponents: true, // Optional(default: true)-false,if you want to localy install component
// vueGoogleMapsCompatibility: false, // Optional (default: false) - true, requires vue2-google-maps
// // to be configured see https://github.com/xkjyeah/vue-google-maps
// vueGoogleMapsCompatibility: true,
});
// console.log(`FB: ${process.env.VUE_APP_PANEL_URL}/auth/facebook/callback`);
Vue.use(VueAxios, axios);
Vue.use(VueSocialauth, {
  providers: {
    google: {
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      redirectUri: `${process.env.VUE_APP_PANEL_URL}/auth/google/callback`,
    },
    facebook: {
      clientId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
      redirectUri: `${process.env.VUE_APP_PANEL_URL}/auth/facebook/callback`,
    },
  },
});

Vue.use(VueTelInputVuetify, {
  vuetify,
});
// Components
Vue.component('MainLayout', require('./components/MainLayout.vue').default);
Vue.component('Navigation', require('./components/Navigation.vue').default);
Vue.component('ErrorsBackend', require('./components/ErrorsBackend.vue').default);
Vue.component('Modal', require('./components/Modal.vue').default);
Vue.component('Number', require('./components/Number.vue').default);
Vue.component('AuthLayout', require('./views/auth/AuthLayout.vue').default);

Vue.component('MediaLibraryAttachment', MediaLibraryAttachment);
Vue.component('MediaLibraryCollection', MediaLibraryCollection);
require('@spatie/media-library-pro-styles');

// Pages
Vue.component('FormActionButtons', require('./views/ads/forms/FormActionButtons.vue').default);

Vue.config.productionTip = false;

Vue.use(constPlugin);

Vue.use(VuePageTitle, {
  // prefix: 'My App - ',
  suffix: '- Bookaweb.com',
});

new Vue({
  router,
  store,
  created() {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          this.$store.dispatch('logout');
        }
        return Promise.reject(error);
      },
    );

    const token = storage.getItem('access_token');
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      this.$store.dispatch('loadProfile');
    }

    axios.defaults.headers.common['App-Language'] = this.$i18n.locale;
  },

  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

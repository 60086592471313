<template>
  <div class="d-flex align-center justify-space-between">
    <div>
      {{label}}
    </div>
    <div class="d-flex align-center">
      <v-btn
        class="ml-3"
        @click="minus"
        fab
        outlined
        x-small
      >
        <v-icon>mdi-minus</v-icon>
      </v-btn>
      <span class="ml-3">
        {{ number }}
      </span>
      <v-btn
        class="ml-3"
        @click="plus"
        fab
        outlined
        x-small
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  // props: ['value', 'label'],
  props: {
    value: {
      required: true,
      type: Number,
    },
    label: {
      required: true,
      type: String,
    },
    minimum: {
      required: false,
      type: Number,
      default: 1,
    },
    max: {
      required: false,
      type: Number,
      default: 1000000,
    },
  },
  data() {
    return {
      number: this.value,
    };
  },
  watch: {
    value(value) {
      this.number = value;
    },
  },
  methods: {
    plus() {
      if (this.number + 1 > this.max) {
        return;
      }
      this.number += 1;
      this.$emit('input', this.number);
    },
    minus() {
      if (this.number === this.minimum) {
        return;
      }
      this.number -= 1;
      this.$emit('input', this.number);
    },
  },
};
</script>

<template>
  <div
    class="form-actions-wrapper mobile"
  >
    <div
      class="d-flex pa-0"
      style="width: 100%;"
      :class="{
        'justify-space-between': showBackButton,
        'justify-end': !showBackButton,
        'container': !$vuetify.breakpoint.smAndDown,
      }"
    >
      <v-btn
        v-if="showBackButton"
        :disabled="loading"
        class="mr-5"
        :class="{'pa-0': $vuetify.breakpoint.smAndDown}"
        text
        @click="$emit('prev')"
        >
        {{$t('Back')}}
      </v-btn>
      <v-btn
        :loading="loading"
        color="primary"
        @click="$emit('submit')"
      >
        {{ submitButtonText }}
      </v-btn>
    </div>
  </div>
</template>
<style scoped>
.form-actions-wrapper{
  margin-top: 40px;
  margin-bottom: 16px;
  display: flex;
  background: white;
  padding: 15px;
}
.form-actions-wrapper.mobile {
  margin-top: 0px;
  margin-bottom: 0px;
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 99;
  border-top: 1px solid lightgray;
}
</style>
<script>
export default {
  props: {
    hideBackButton: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    submitButtonText() {
      return ['pending', 'pending_fix', 'listed', 'unlisted'].includes(this.status)
        ? this.$t('Form Action Button Update')
        : this.$t('Form Action Button Continue');
    },
    showBackButton() {
      if (this.hideBackButton) {
        return false;
      }
      if (['pending', 'pending_fix', 'listed', 'unlisted'].includes(this.status)) {
        return false;
      }
      return true;
    },
  },
};
</script>

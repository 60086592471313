<template>
  <div>
    <!-- pokradi od asane -->
    <v-app-bar
        style="border-bottom:1px solid #f2f2f2 !important;z-index:20;"
        :color="backgroundColor"
        :dark="src ? true : dark"
        :shrink-on-scroll="src ? true : false"
        :src="src"
        fixed
        app
        flat
        extension-height="35"
      >
        <template v-slot:img="{ props }" v-if="src">
          <v-img
            v-bind="props"
            gradient="to top right, rgba(0,0,0,.7), rgba(25,32,72,.7)"
          ></v-img>
        </template>

        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title style="min-width:60px; cursor:pointer;" @click="goToWebsite">
            <!-- <small>(Host panel)</small> -->
            <!-- {{ page.name }} -->
            <!-- <v-img
              lazy-src="@/assets/logotip.svg"
              src="@/assets/logotip.svg"
          ></v-img> -->
          <svg width="30" height="40" viewBox="0 0 96 122" fill="none" xmlns="http://www.w3.org/2000/svg">
            <!-- eslint-disable-next-line -->
            <path d="M72.8148 49.3306C75.5157 44.2457 76.7312 38.6471 76.5513 32.7964L76.5513 32.796C75.9935 15.0265 60.7546 0.534637 42.6468 0.534637H14.001C6.95206 0.534637 1.23729 6.25338 1.23729 13.3059V108.729C1.23729 115.781 6.95206 121.5 14.001 121.5H57.0154C77.874 121.5 95.0348 104.803 95.3131 84.2846L95.3132 84.2808C95.4048 69.0627 86.3562 55.386 72.8148 49.3306ZM8.73521 13.3059V13.2988L8.73501 13.2916C8.65176 10.3762 11.0713 7.945 14.001 7.945H42.7383C56.8441 7.945 68.6968 19.2765 69.1454 33.1027L69.1455 33.1046C69.3192 37.8836 68.1488 42.4125 65.8717 46.5329L42.5006 35.11C33.4658 30.684 22.4576 34.4508 18.0354 43.583L18.0351 43.5836C14.1372 51.6628 16.6482 61.2096 23.5864 66.3474C22.6479 69.9063 22.684 73.6434 23.9627 77.2095C25.5615 81.8187 28.8542 85.5842 33.2749 87.7489L33.2777 87.7503C35.8113 88.9709 38.5392 89.6313 41.274 89.6313C43.2573 89.6313 45.3244 89.2537 47.2894 88.5983L47.2952 88.5963C51.9029 86.9968 55.667 83.7017 57.8306 79.2776L57.8308 79.2772L69.2488 55.8875C80.5326 60.5878 87.9947 71.7237 87.9068 84.0886C87.6356 100.566 73.7718 113.998 57.0154 113.998H14.001C11.0742 113.998 8.73521 111.658 8.73521 108.729V13.3059ZM39.2095 41.8618L39.2167 41.8656L39.2241 41.8692L62.2049 53.0972L51.0769 75.9955C51.0766 75.9963 51.0762 75.997 51.0758 75.9978C49.7613 78.6271 47.5757 80.5512 44.7721 81.5156C41.9678 82.4801 39.0871 82.3041 36.4641 80.9917C33.8354 79.6766 31.9115 77.4893 30.9475 74.6829C29.9834 71.8767 30.1594 68.9939 31.471 66.3691C31.9055 65.4996 31.9003 64.4419 31.5867 63.5979C31.2704 62.6555 30.632 61.9071 29.6819 61.4811C27.0582 60.1654 25.1379 57.98 24.175 55.1771C23.2109 52.3709 23.3869 49.4881 24.6985 46.8633C26.6266 43.0046 30.4796 40.7294 34.5015 40.7294C36.0776 40.7294 37.7344 41.0803 39.2095 41.8618Z" fill="#222222" stroke="#222222"/>
          </svg>

        </v-toolbar-title>
        <!-- <v-toolbar-title>{{ page.name }}</v-toolbar-title> -->
        <v-toolbar-items class="hidden-sm-and-down main-nav">
            <v-list-item
              link
              dense
              :to="{name:'Dashboard'}"
              :class="{ 'active-link': $route.name === 'Dashboard'}"
            >
              <v-list-item-title>{{ $t('Dashboard') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="category in categoryLinks"
              :to="`/listings/${category.const}`"
              :key="category.id"
              link
              dense
              :class="{ 'active-link': ['Ads','AdForm'].includes($route.name) && $route.params && $route.params.categoryConst && $route.params.categoryConst === category.const}"
            >
              <v-list-item-title>{{ category.name[$i18n.locale] }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="{name:'user-settings.account'}"
              :class="{ 'active-link': ['user-settings.account', 'user-settings.change-password', 'user-settings.payouts'].includes($route.name)}"
              link
              dense
            >
                <v-list-item-title
                  :class="{ 'active-link-item': ['user-settings.account', 'user-settings.change-password', 'user-settings.payouts'].includes($route.name)}"
                >{{ $t('Account Settings') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
             v-if="user.apartmentCount > 0"
              :to="{name:'reservations'}"
              active-class="active-link"
              link
              dense
            >
              <v-list-item-title
                :class="{ 'active-link-item': $route.name === 'reservations'}"
              >{{ $t('Reservations') }}</v-list-item-title>
            </v-list-item>

            <v-list-item
             v-if="user.apartmentCount > 0"
              :to="{name:'inquiries'}"
              active-class="active-link"
              link
              dense
            >
              <v-list-item-title
                :class="{ 'active-link-item': $route.name === 'reservations'}"
              >{{ $t('Inquiries') }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              :to="{name:'activity-log'}"
              active-class="active-link"
              link
              dense
            >
              <v-list-item-title
                :class="{ 'active-link-item': $route.name === 'activity-log'}"
              >{{ $t('Activity Log') }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              :to="{name:'my-travel.reservations'}"
              :class="{ 'active-link': ['my-travel.inquiries', 'my-travel.reservations'].includes($route.name)}"
              link
              dense
            >
                <v-list-item-title
                  :class="{ 'active-link-item': ['my-travel.inquiries', 'my-travel.reservations'].includes($route.name)}"
                >{{ $t('My Travel') }}</v-list-item-title>
            </v-list-item>

            <!-- <v-list-item
              v-if="isAdmin"
              :to="{name:'TV'}"
              :class="{ 'active-link': $route.name === 'TV'}"
              link
              dense
            >
                <v-list-item-title
                  :class="{ 'active-link-item': $route.name === 'TV'}"
                >TV</v-list-item-title>
            </v-list-item> -->
        </v-toolbar-items>
        <!-- <v-tabs class="hidden-sm-and-down main-nav">
          <v-tab to='/' class="custom-transform-none">{{ $t('Dashboard') }}</v-tab>
          <v-tab to='/ads' class="custom-transform-none">{{ $t('Ads') }}</v-tab>
          <v-tab to='/user-settings' class="custom-transform-none"
          >{{ $t('Account Settings') }}</v-tab>
        </v-tabs> -->

        <v-spacer></v-spacer>

        <!-- <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn> -->

        <!-- <v-btn icon>
          <v-icon>mdi-heart-outline</v-icon>
        </v-btn> -->

        <v-btn class="mr-2" text outlined small :to="{ name: 'AdsCreate' }">
          {{ $t('Create Ad') }}
        </v-btn>

        <v-menu
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
            >
              <img  v-if="$i18n.locale === 'sr'"
                width="20"
                alt="SR"
                src="@/assets/flag-sr.svg" />
              <img v-else
                width="20"
                alt="EN"
                src="@/assets/flag-en.svg" />
            </v-btn>
          </template>

          <v-list class="py-0">
            <v-list-item link @click="setLocale('sr')" dense>
              <v-list-item-icon class="mr-2">
                <img width="20" src="@/assets/flag-sr.svg"
                  alt="SR"/>
              </v-list-item-icon>
              <v-list-item-title>SR</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="setLocale('en')" dense>
              <v-list-item-icon class="mr-2">
                <img width="20" src="@/assets/flag-en.svg"
                  alt="EN"/>
              </v-list-item-icon>
              <v-list-item-title>EN</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- <v-tooltip bottom v-if="user">
            <template v-slot:activator="{ on }">
              <v-avatar
                class="mr-3 ml-2"
                color="primary white--text pointer"
                size="28"
                v-on="on"
              >{{user.first_name.charAt(0).toUpperCase()}}{{user.last_name.charAt(0).toUpperCase()}}
              </v-avatar>
            </template>
          <span>{{user.first_name}} {{user.last_name}}</span>
        </v-tooltip> -->
        <template v-if="user">
          <div class="text-center">
            <v-menu
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  elevation="0"
                  color="white"
                  class="px-2"
                >
                <v-avatar
                  class="ml-2"
                  color="primary white--text pointer"
                  size="28"
                  v-on="on"
                >
                  <template
                    v-if="user.media_avatar && Object.values(user.media_avatar).length && Object.values(user.media_avatar)[0].original_url"
                  >
                    <img :src="Object.values(user.media_avatar)[0].original_url" alt="User avatar">
                  </template>
                  <template v-else>
                    {{user.first_name.charAt(0).toUpperCase()}}{{user.last_name.charAt(0).toUpperCase()}}
                  </template>
                </v-avatar>
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
              </template>
              <v-list class="py-0">
                <v-list-item link @click.stop="logout">
                  <v-list-item-action>
                      <v-icon>mdi-logout</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                      <v-list-item-title>{{$t('Logout')}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>

        <!-- <template v-slot:extension v-if="src">
          <div class="d-flex" align-with-title>
            <h3>{{ page.name }}</h3>
          </div>
        </template> -->
        <!-- <template v-slot:extension>
          <v-tabs height="35">
            <v-tab to='/' class="">Dashboard</v-tab>
            <v-tab to='/ads' class="">Ads</v-tab>
            <v-tab to='/user-settings'>{{ $t('Account Settings') }}</v-tab>
          </v-tabs>
        </template> -->
      <v-progress-linear
        absolute
        bottom
        :active="loading"
        indeterminate
        class="ma-0"
        height="3"
        style="bottom: -2px;"
      />
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :color="backgroundColor"
      :expand-on-hover="expandOnHover"
      :mini-variant="miniVariant"
      :right="right"
      disable-resize-watcher
      :permanent="permanent"
      :dark="dark"
      app
    >
      <v-list
        dense
        nav
        class="py-0 pt-3"
      >
        <v-list-item :class="miniVariant && 'px-0'">
            <v-img
                class="mr-auto mb-2"
                max-width="150"
                lazy-src="@/assets/logo.svg"
                src="@/assets/logo.svg"
            ></v-img>
        </v-list-item>
        <v-list-item :class="miniVariant && 'px-0'">
          <div class="caption">
            {{$t('User Panel')}}
          </div>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link to="/">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('Dashboard') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{name:'AdsCreate'}"
          :class="{ 'v-list-item--active': ['AdsCreate'].includes($route.name)}"
        >
          <v-list-item-icon>
            <v-icon>mdi-pencil-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('Create Ad') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-for="category in categoryLinks"
          :key="category.id"
          link  dense :to="`/listings/${category.const}`"
          :class="{ 'v-list-item--active': ['Ads','AdForm'].includes($route.name) && $route.params && $route.params.categoryConst && $route.params.categoryConst === category.const}"
        >
          <v-list-item-icon>
            <v-img :src="category.icon" max-width="20" max-height="20"/>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ category.name[$i18n.locale] }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{name:'user-settings.account'}"
          :class="{ 'v-list-item--active': ['user-settings.account', 'user-settings.change-password', 'user-settings.payouts'].includes($route.name)}"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('Account Settings') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{name:'activity-log'}">
          <v-list-item-icon>
            <v-icon>mdi-timeline-clock-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('Activity Log') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-if="user && user.apartmentCount > 0">
          <v-list-item
              link :to="{name:'reservations'}">
            <v-list-item-icon>
              <v-icon>mdi-calendar-clock-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('Reservations') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
              link :to="{name:'inquiries'}">
            <v-list-item-icon>
              <v-icon>mdi-calendar-question-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('Inquiries') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item
            link :to="{name:'my-travel.reservations'}">
          <v-list-item-icon>
            <v-icon>mdi-wallet-travel</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('My Travel') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item
          v-if="isAdmin"
          :to="{name:'TV'}"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-television</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>TV</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

          <v-list-item link @click.stop="logout">
            <v-list-item-action>
                <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>{{$t('Logout')}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<style lang="scss">
  .custom-transform-none{
    text-transform: none;
  }
  .main-nav {
    max-width: 100%;
    overflow-x: auto;
  }
  .main-nav .v-list-item--link:before{
    background-color: transparent;
  }
  .main-nav .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #484848 !important;
  }
  .active-link{
     box-shadow: inset 0 -2px 0 black;
  }
  .active-link-item{
    font-weight:600 !important;
  }
</style>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  data() {
    return {
      drawer: false,
      color: undefined,
      colors: [
        'primary',
        'blue',
        'success',
        'red',
        'teal',
      ],
      right: false,
      permanent: false,
      miniVariant: false,
      expandOnHover: false,
      background: false,

      categories: [],
    };
  },
  computed: {
    ...mapGetters(['isLogged', 'settings', 'user', 'loading', 'isAdmin']),
    dark() {
      return false;
    },
    backgroundColor() {
      if (this.dark === true) {
        return undefined;
      }
      if (this.color === undefined) {
        return 'white';
      }
      return this.color;
    },
    src() {
      return undefined;
    },
    categoryLinks() {
      return this.categories.filter((category) => category.ads_count > 0);
    },
  },
  watch: {
    dark(val) {
      this.$emit('dark', val);
    },
  },
  mounted() {
    // ToDo > move this to vuex or broadcast
    this.$store
      .dispatch('getAdCategories', 'adsUserCount')
      .then((categories) => {
        this.categories = categories;
      });

    // console.log('this routers');
    // console.log(this.$route);
    if (this.$i18n && this.$i18n.locale) {
      this.hideHubspotChatIfNotSrLocale(this.$i18n.locale);
    }
  },
  methods: {
    goToWebsite() {
      window.location.href = `${process.env.VUE_APP_APP_URL}${this.$i18n.locale === 'sr' ? `/${this.$i18n.locale}` : ''}`;
    },
    logout() {
      this.$store.dispatch('logout');
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      const settings = { ...this.settings };
      settings.lang = locale;
      axios.defaults.headers.common['App-Language'] = this.$i18n.locale;
      this.$store.dispatch('updateSettings', settings);

      this.hideHubspotChatIfNotSrLocale(locale);
    },
    hideHubspotChatIfNotSrLocale(locale) {
      if (locale !== 'sr') {
        document.body.classList.add('hide-hubspot-chat');
      } else {
        document.body.classList.remove('hide-hubspot-chat');
      }
    },
  },
};
</script>
